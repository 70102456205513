<template>
  <div
    class="ui-chart-pie"
    style="position: relative; min-height: 250px;"
  >
    <canvas
      v-bind="$attrs"
      style="width:100%;height:100%;"
    ></canvas>
  </div>
</template>

<script>
/*
value = [
  {label: 'Si', value: 10, color: 'rgb(255, 99, 132)'},
  {label: 'No', value: 20, color: 'rgb(75, 192, 192)'},
  {label: 'Que', value: 30, color: 'rgb(54, 162, 235)'},
  {label: 'What', value: 50, color: 'green'},
];
*/

import Chart from 'chart.js';

export default {
  name: 'ui-chart-pie',

  props: {
    value: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: false,
      default: 'pie',
      validator: v => ['pie', 'doughnut'].includes(v)
    }
  },

  data() {
    return {
      chartInstance: null,
      chartData: {
        type: 'pie',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onClick: evt => {
            // ver https://www.chartjs.org/docs/latest/developers/api.html#getelementatevente
            let firstPoint = this.chartInstance.getElementAtEvent(evt)[0];
            if (!firstPoint) {
              return;
            }
            this.$emit('click-point', this.value[firstPoint._index]);
          }
        }
      },

      defaultColors: [
        'rgb(54, 162, 235)',
        'rgb(75, 192, 192)',
        'rgb(255, 159, 64)',
        'rgb(153, 102, 255)',
        'rgb(255, 99, 132)',
        'rgb(255, 205, 86)'
      ]
    };
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.buildChartData();
        this.chartInstance.update();
      }
    }
  },

  mounted() {
    this.buildChartData();

    this.chartInstance = new Chart(
      this.$el.querySelector('canvas'),
      this.chartData
    );
  },

  methods: {
    buildChartData() {
      this.chartData.type = this.type;

      this.chartData.data.labels = [];
      this.chartData.data.datasets[0].data = [];
      this.chartData.data.datasets[0].backgroundColor = [];

      this.value.forEach((stat, i) => {
        this.chartData.data.labels.push(stat.label);
        this.chartData.data.datasets[0].data.push(stat.value);

        let color = stat.color
          ? stat.color
          : this.defaultColors[i % this.defaultColors.length];

        this.chartData.data.datasets[0].backgroundColor.push(color);
      });
    }
  }
};
</script>